import React from 'react';

function Avatar({
  pullUp = false,
  src,
  size = 'md',
  title = 'avatarImg',
  color = 'light-primary',
}) {
  return (
    <div
      className={`avatar ${pullUp ? 'pull-up' : ''} ${
        size !== 'md' ? `avatar-${size}` : ''
      }  ${src ? '' : `bg-${color}`}`.trim()}
    >
      {src ? (
        <img src={src} alt={title} height="32" width="32" />
      ) : (
        <span className="avatar-content">
          {title
            .split(' ')
            .map((m) => m[0].toUpperCase())
            .join('')}
        </span>
      )}
    </div>
  );
}

function AvatarGroup({ children }) {
  return <div className="avatar-group">{children}</div>;
}

export { Avatar, AvatarGroup };
