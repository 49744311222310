function useGeolocation() {
  async function getCurrentPosition() {
    return new Promise((resolve, reject) => {
      window.navigator?.geolocation?.getCurrentPosition(resolve, reject, {
        enableHighAccuracy: true,
        timeout: 15000,
        maximumAge: 10000,
      });
    });
  }

  return { getCurrentPosition };
}

export default useGeolocation;
