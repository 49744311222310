import React, { createContext, useState, useEffect, useContext } from 'react';

const ThemeContext = createContext({});

const ThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [title, setTitle] = useState(false);

  const toggleTheme = () => {
    const current = document
      .querySelector('body')
      .classList.toggle('dark-theme');

    localStorage.setItem('isDarkMode', `${current ? 1 : 0}`);
    setIsDarkMode(current);
  };

  useEffect(() => {
    const current = Number(localStorage.isDarkMode);
    const defaultTheme =
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches;

    const contains = document
      .querySelector('body')
      .classList.contains('dark-theme');

    if (current || (Number.isNaN(current) && defaultTheme)) {
      if (!contains) {
        setIsDarkMode(true);
        toggleTheme();
      }
    }
  }, []);

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme, title, setTitle }}>
      {children}
    </ThemeContext.Provider>
  );
};

function useTheme() {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error('useTheme must be used within an ThemeProvider.');
  }

  return context;
}

export { ThemeProvider, useTheme };
