/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import Flatpickr from 'react-flatpickr';
import { Portuguese } from 'flatpickr/dist/l10n/pt';

export default function DatePicker({
  name,
  label,
  type,
  className = '',
  mode = 'single',
  ...rest
}) {
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const inputRef = useRef();

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref) => {
        if (mode === 'single') {
          return ref.flatpickr.selectedDates[0];
        }

        return ref.flatpickr.selectedDates;
      },
      setValue: (ref, value) => {
        if (!value) {
          return ref.flatpickr.clear(true);
        }

        const val = [].concat(value).map((m) => {
          let date = m;

          if (date instanceof Date) {
            return date;
          }

          if (date.includes('-')) {
            date = date.split('T')[0].split('-').reverse().join('/');
          }

          return date;
        });

        ref.flatpickr.setDate(val, true);
      },
      clearValue: (ref) => {
        ref.flatpickr.clear(true);
      },
    });
  }, [fieldName, registerField, mode]);

  return (
    <div className="form-group">
      <label htmlFor={fieldName} className="form-label">
        {label}
      </label>
      <Flatpickr
        ref={inputRef}
        options={{
          mode,
          locale: Portuguese,
          dateFormat: 'd/m/Y',
        }}
        defaultValue={defaultValue}
        className={[className, error ? 'error' : ''].join(' ')}
        {...rest}
      />
      {error && <small className="error">{error}</small>}
    </div>
  );
}
